import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, ConfirmationComponent, Notification, NotificationService, NotificationTypeEnum, Pager } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  selector: 'notification-component',
  templateUrl: 'notification.component.html'
})
export class NotificationComponent extends AbstractComponent implements OnInit {

  pager: Pager<Notification> = new Pager<Notification>({ perPage: 25 });

  listObservable: Observable<Pager<Notification>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private store: Store,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    }

    this.notificationService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Notification>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.notificationService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Notification>) => {
      this.pager = pager;
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  delete(notification: Notification) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.notificationService.delete(notification._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success();
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  notify(notification: Notification) {
    if (notification.type === NotificationTypeEnum.WARNING_WORD) {
      if (!!notification.warningWord && !!notification.attendance) {
        this.router.navigate(['/attendance/panel'], { queryParams: { id: notification.attendance._id, status: notification.attendance.status } });
      }
    }
  }

}
