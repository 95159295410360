import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { NotificationRoutingModule } from './notification-routing.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  imports: [
    SharedModule,
    NotificationRoutingModule,
  ],
  declarations: [
    NotificationComponent,
  ],
  exports: [
    NotificationComponent,
  ],
  providers: [],
  schemas: []
})
export class NotificationModule { }
